/**
 * Link
 * https://nextjs.org/docs/app/api-reference/components/link
 * 
 * @Author: Focci
 * @Date: 2025-03-20 13:35:02
 * @Last Modified by: Focci
 * @Last Modified time: 2025-03-20 13:35:02
 */

import Link from 'next/link'

export function LinkTo({ children, ...props }) {
  return (
    <Link {...props}>
      {children}
    </Link>
  )
}
