import useMessage from '@hook/useMessage'
import { delay } from 'lodash-es'
import { Button, ButtonBase } from '@comp/material'
import { useTranslations } from 'next-intl'
import { useForm } from 'react-hook-form'
import { cookieGet, cookieSet } from '@lib/utils'
import { fetchPost, fetchProxy } from '@lib/fetch/client'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getCookieOption } from '@lib/get-cookie-option'
import { ENQUIRY_FORM_PARAM } from '@lib/config'
import useLogin from './useLogin'
import usePortal from './usePortal'

export const emailRules = { 
  required: 'Email is required!',
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: 'Invalid email address',
  }
}

/**
 * 弹出输入验证码提示
 */
function TypeCaptchaDialog({
  email,
  param = {},
  onSendSuccess = () => {}
}) {
  const inputRef = useRef()
  const tl = useTranslations('login')
  const te = useTranslations('enquiry')
  const { message } = useMessage()

  const [value, setValue] = useState('')
  const [url, setUrl] = useState()
  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)

  /**
   * 获取验证码
   */
  const getCaptcha = useCallback(() => {
    !loading && fetchProxy(
      fetchPost('captcha', { identity: email }),
      setLoading
    ).then((data) => {
      setUrl(data?.src)
    })
  }, [email, loading])

  useEffect(() => {
    getCaptcha()
    inputRef.current.focus()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * 重新发送邮件
   */
  const resendEmail = useCallback(() => {
    fetchProxy(
      fetchPost('user/enquiry', { ...param, captcha: value }), 
      setSending
    ).then(() => {
      setValue('')
      onSendSuccess()
    }).catch((err) => {
      message(err?.message)
    })
  }, [param, value, message, onSendSuccess])
  
  return (
    <div className="bg-white p-4 rounded-md cursor-default max-w-full w-60">
      <div className="flex flex-col items-center gap-4">
        <ButtonBase onClick={getCaptcha}>
          <div className="bg-cf0 w-32 h-10">
            {url && <img 
              src={url} 
              alt="captcha"
              width={180} 
              height={50}
              className="w-32 h-10"
            />}
          </div>
        </ButtonBase>
        <input
          ref={inputRef}
          value={value} 
          onChange={(e) => setValue(e.target.value)}
          placeholder={tl('enterCode')}
          className="bg-cf0 hover:bg-primary w-full h-8 text-center outline-none px-2"
        />
        <Button
          disabled={value.trim().length !== 4 || sending}
          onClick={resendEmail}
        >
          {sending ? 'loading...' : te('sendEmail')}
        </Button>
      </div>
    </div>
  )
}

export function setEnquiryCookie(param = {}) {
  const option = getCookieOption()
  cookieSet(ENQUIRY_FORM_PARAM, param, option)
}

export function useEnquiryInfo(props) {
  const { defaultSpecs = [] } = props || {}
  const te = useTranslations('enquiry')

  const { mountDialog } = usePortal()
  const { message } = useMessage()
  const { isLogin, openSigninDialog } = useLogin()
  const [loading, seLoading] = useState(false)
  const [spec, setSpec] = useState(defaultSpecs)
  const form = useForm()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const enquiryParam = useMemo(() => cookieGet(ENQUIRY_FORM_PARAM, true), [isLogin])
  const userName = useMemo(() => (enquiryParam?.name || ''), [enquiryParam])
  const userEmail = useMemo(() => (enquiryParam?.email || ''), [enquiryParam])
  const userMobile = useMemo(() => (enquiryParam?.phoneNumber || ''), [enquiryParam])

  const timer = useRef()

  const submitEnquiry = useCallback((param) => (
    new Promise((resolve, reject) => {
      fetchProxy(fetchPost('user/enquiry', param), seLoading)
        .then(() => {
          message(te('sendEmailSuccessfuly'))
          resolve()
        })
        .catch(async (err) => {
          if(err?.status === 459) { // 需要登录
            openSigninDialog()
            reject()
          } else if(err?.status === 458) { // 需要输入验证码
            const res = await mountDialog(
              <TypeCaptchaDialog 
                email={param.email}
                param={param}
                onSendSuccess={() => {
                  message(te('sendEmailSuccessfuly'))
                  resolve()
                  timer.current = delay(() => res.destroy(), 1500)
                }}
              />
            )
          } else {
            message(err?.message || te('sendEmailFailed'))
            reject()
          }
        })
        .finally(() => setEnquiryCookie(param))
    })
  ), [message, mountDialog, openSigninDialog, te])

  const reset = useCallback(() => {
    setSpec([])
    form.reset()
  }, [form])

  useEffect(() => () => timer.current && clearTimeout(timer.current), [])

  return {
    spec,
    form,
    loading,
    userName,
    userMobile,
    userEmail,
    emailRules,
    setSpec,
    reset,
    submitEnquiry,
  }
}
