/**
 * SlideMessage
 * 
 * @Author: Focci
 * @Date: 2024-10-31 16:00:11
 * @Last Modified by: Focci
 * @Last Modified time: 2024-10-31 16:00:11
 */

// import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import { LinkTo } from '@comp/LinkTo'
import { IconButton } from '@comp/material'
import { useBoolean } from 'react-use'
import { useLocale, useTranslations } from 'next-intl'
import { useMemo } from 'react'
import { campaignEvent, getUrl } from './_'

export default function SlideMessage(props) {
  const { data, onClose } = props
  const locale = useLocale()

  const ts = useTranslations('search')
  const [open, setOpen] = useBoolean(Boolean(data))
  
  function closeHandle() {
    setOpen(false)
    onClose()
  }
  
  const src = useMemo(() => (
    getUrl(data?.action?.primary, locale)
  ), [data?.action?.primary, locale])

  return open && (
    <div className="fixed bottom-10 left-1/2 -translate-x-1/2 z-[1000] flex justify-center">
      <div className="flex items-center gap-4 bg-primary text-white px-4 py-3 rounded-md w-[50rem] max-w-[80%]">
        <div className="flex-1 break-all text-f.8">
          {data.body}
        </div>
        <div className="shrink-0 flex items-center gap-1 -mr-2">
          <LinkTo 
            href={src} 
            target="_blank"
            className="flex items-center h-5 px-2 rounded-sm text-white border border-white hover:bg-white/10"
            onClick={() => campaignEvent(data.pushId, 'primary')}
          >
            {ts('view')} 
          </LinkTo>
          <IconButton sx={{ p: '4px' }} onClick={closeHandle}>
            <CloseIcon className="text-white" />
          </IconButton>
        </div>
      </div>
    </div>
  )
}
