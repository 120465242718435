import md5 from 'blueimp-md5'
import { API_BASE_CLIENT, API_BASE_SERVER, API_VERSION, PUBLIC_TOKEN } from '@lib/config'

/**
 * 需要从 Response.headers 里面提取的数据
 */
const EXPOSE_HEADERS = [
  'x-total-count',
  'x-total-display',
  'x-selected-count',
  'x-total-assets',
]

/**
 * 获取接口地址的完整路径
 */
export function getAbsoluteUrl(apiUrl, isServer) {
  if (/^http(?:.*)/.test(apiUrl)) {
    return apiUrl
  }

  const tmp = [isServer ? API_BASE_SERVER : API_BASE_CLIENT]
  if (API_VERSION) {
    tmp.push(API_VERSION)
  }

  tmp.push(apiUrl)
  return tmp.join('/')
}

/**
 * 生成带请求参数的url
 */
export function generateUrl(absoluteUrl, query) {
  const searchParamString = query?.forEach
    ? query.toString() // URLSearchParams 实例对象过来的参数
    : Object.keys(query ?? {})
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
      .join('&')

  const url = new URL(absoluteUrl)
  if (searchParamString) {
    url.search = `?${searchParamString}`
  }

  return url.toString()
}

/**
 * 通过url生成签名
 */
export function signUrl(apiUrl, timestamp = null) {
  const url = String(apiUrl).split('')
  const reg = /[a-z0-9]/i
  const urlMap = {}
  const urlList = []

  url.forEach((item) => {
    if (reg.test(item)) {
      if (urlMap[item]) {
        urlMap[item]++
      } else {
        urlMap[item] = 1
        urlList.push(item)
      }
    }
  })

  let str = ''
  urlList.forEach((key) => {
    str += `${key}${urlMap[key]}`
  })
  if (timestamp) {
    str += timestamp
  } else {
    str += new Date().getTime()
  }

  return md5(str)
}

/**
 * 获取Fetch请求参数
 * @returns {Object}
 */
export function getFetchParams(url, body, options, token, uuid) {
  const Timestamp = 1688631488947
  // const Timestamp = Date.now()
  const headers = {
    Timestamp,
    Sign: signUrl(url, Timestamp),
    Authorization: token ? `User ${token}` : PUBLIC_TOKEN,
    Client: 'web',
    'Content-Type': 'application/json',
    ...(options?.headers || {})
  }

  uuid && (headers['User-uuid'] = uuid)

  return {
    ...options,
    body,
    headers,
  }
}

/**
 * 生成fetch所需要的参数
 * 
 * @param {String} method 类型
 * @param {String} apiUrl 接口地址,首尾不需要'/'
 * @param {Object} query 请求参数
 * @param {Object} options fetch options https://developer.mozilla.org/zh-CN/docs/Web/API/fetch
 * @param {String} token token
 * @param {String} uuid uuid
 * @param {Boolean} isServer 是否next.js服务端请求
 * @returns 
 */
function initialFetch(method, apiUrl, query, options, token, uuid, isServer) {
  const hasBody = ['PUT', 'POST'].includes(method.toLocaleUpperCase())
  const absoluteUrl = getAbsoluteUrl(apiUrl, isServer)
  const url = generateUrl(absoluteUrl, hasBody ? null : query)
  const param = getFetchParams(
    url,
    hasBody ? JSON.stringify(query) : undefined,
    options,
    token,
    uuid,
  )
  return {
    url,
    fetchParam: { ...param, method },
    isServer
  }
}

function printError(data, url, param) {
  if(data?.code !== 404) {
    const { body, method } = param || []
    // eslint-disable-next-line no-console
    console.log('API_ERROR', `${method}: ${url}`, body || {}, data)
  }
}

/**
 * fetch 请求，参数见 initialFetch
 */
export async function fetchData(...args) {
  const { url, fetchParam, isServer } = initialFetch(...args)
  let res
  let data = null
  
  try { 
    res = await fetch(url, fetchParam)
    data = await res.json()
    if(!res.ok && isServer) {
      printError(data, url, fetchParam)
    }
  } catch (err) {
    isServer && printError(err, url, fetchParam)
  }

  if (!data) return data

  if (res?.ok) {
    try {
      const headers = { status: res.status }
      EXPOSE_HEADERS.forEach((key) => {
        const val = res.headers.get(key)
        if (val) {
          headers[key] = /^\d+$/.test(val) ? parseFloat(val) : val
        }
      })

      data.headers = headers
    } catch (err) { /**/ }

    return data
  }
  
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({
    status: res?.status,
    statusText: res?.statusText,
    ...data
  })
}

export function getRealLang(lang) {
  const langMap = { 'zh-tw': 'zh_TW', 'zh-hk': 'zh_HK' }
  return langMap[lang] || lang
}
