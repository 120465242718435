/**
 * UserGuide
 * 
 * @Author: Focci
 * @Date: 2025-03-12 11:35:49
 * @Last Modified by: Focci
 * @Last Modified time: 2025-03-12 11:35:49
 */

'use client'

// import PropTypes from 'prop-types'
import useLazyComponent from '@hook/useLazyComponent'
import { isWindow, cookieGet } from '@lib/utils'
import { useEffect, useRef, useState } from 'react'
import { HG_CURRENT_COUNTRY_NAME } from '@lib/config'

export default function UserGuide() {
  const [count, setCount] = useState(0)
  const eles = useRef([])

  const { Wedget: UserGuideBody, getWedget, ready } = useLazyComponent()

  useEffect(() => {
    let observer
    if(isWindow) {
      observer = new MutationObserver(() => {
        const locale = !!cookieGet(HG_CURRENT_COUNTRY_NAME)

        const root = document.querySelector('.user-guide-root') || document.querySelector('.MuiDialog-container') || document
        eles.current = root.querySelectorAll('*[data-userguide-title]')

        const len = eles.current.length

        setCount(len)
        !ready && len > 0 && locale && getWedget(() => import('./UserGuideBody'))
      })

      observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true,
      })
    }

    return () => observer?.disconnect?.()
  }, [getWedget, ready])

  return <UserGuideBody eles={eles} count={count} />
}
