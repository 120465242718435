import { map } from 'lodash-es'
import { getRouteUrl } from './lib'
import { list as newsList } from './news'
import { homepage } from './topic'
import { list as knowledgeList } from './knowledge'
import { list as liveList } from './live'
// import { getRouteUrl } from './lib'

/**
 * 首页
 */
export function home(locale) {
  return getRouteUrl('/', null, locale)
}

/**
 * 登录
 */
export function login(locale) {
  return getRouteUrl('/login', null, locale)
}

/**
 * 注册
 */
export function register(locale) {
  return getRouteUrl('/register', null, locale)
}

/**
 * 登录
 */
export function deleteAccount(locale) {
  return getRouteUrl('/delete-hougarden-account', null, locale)
}

/**
 * 新闻首页
 */
export function newsHomepage(...args) {
  return newsList(...args)
}

/**
 * fm首页
 */
export function fmHomepage(query, locale) {
  const queryStr = map(query, (val, key) => [key, val].join('=')).join('&')
  return getRouteUrl(`/fm/category-all${query ? `?${queryStr}` : ''}`, null, locale)
}

/**
 * topics首页
 */
export function topicHomepage(...args) {
  return homepage(...args)
}

/**
 * 知道首页
 */
export function knowledgeHomepage(...args) {
  return knowledgeList(...args)
}

/**
 * 直播首页
 */
export function liveHomepage(...args) {
  return liveList(...args)
}

/**
 * “后花园找房”软件用户注册协议
 */
export function protocol(locale) {
  return getRouteUrl('/protocol', null, locale)
}

/**
 * “后花园找房”软件用户注册协议
 */
export function about({ slug }, locale) {
  return getRouteUrl('/about-us/{slug}', { slug }, locale)
}

/**
 * 微信登录回调
 */
export function wechatLoginCallbackPage(params) {
  // return getRouteUrl('/wechat-login-callback')
  const url = new URL('https://nz.hougarden.com/wechat-login-callback')
  if (params) {
    const keys = Object.keys(params)
    keys.forEach((key) => {
      url.searchParams.set(key, params[key])
    })
  }
  return url.href
}

/**
 * 微信登录授权
 */
export function wechatLoginAuth() {
  const redirectUrl = new URL(window.location.href)
  redirectUrl.searchParams.delete('code')
  redirectUrl.searchParams.delete('state')
  const url = new URL('https://open.weixin.qq.com/connect/oauth2/authorize')
  url.searchParams.append('appid', process.env.NEXT_PUBLIC_WECHAT_SERVICE_APP_ID)
  url.searchParams.append('redirect_uri', encodeURI(redirectUrl.toString()))
  url.searchParams.append('response_type', 'code')
  url.searchParams.append('scope', 'snsapi_userinfo')
  url.searchParams.append('state', 1)
  url.hash = 'wechat_redirect'
  return url.toString()
}

/**
 * footer link
 */
export function footerLink(type, slug, query, locale) {
  const queryStr = map(query, (val, key) => [key, val].join('=')).join('&')
  return getRouteUrl(`/footer/${type}/${slug}${query ? `?${queryStr}` : ''}`, null, locale)
}

/**
 * 视频
 */
export function videoPage(slug = []) {
  return getRouteUrl(`/video/${slug.join('/')}`)
}
