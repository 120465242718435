/**
 * System Initialization
 * 
 * @Author: Focci
 * @Date: 2023-04-17 11:08:45
 * @Last Modified by: Focci
 * @Last Modified time: 2023-04-17 11:08:45
 */

'use client'

import UserGuide from '@comp/user-guide'
import SysMsgProvider from '@comp/sys-msg'
import BindUnverifiedEmail from '@comp/page/common/login/bind/email/BindUnverifiedEmail'
import InitialSetting from '@comp/page/common/lang/initial'
import dynamic from 'next/dynamic'
import { defaultConfig } from '@lib/theme'
import { RecoilRoot } from 'recoil'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { MessageProvider } from './MessageProvider'
import { AppProvider } from './AppProvider'

export const themeDefault = createTheme(defaultConfig)
const CustomNProgress = dynamic(() => import('@comp/CustomNProgress'), { ssr: false })

export default function AppInitialization({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeDefault}>
        <RecoilRoot>
          <MessageProvider>
            <AppProvider>
              <SysMsgProvider>
                { children }
              </SysMsgProvider>
              <BindUnverifiedEmail />
              {/* https://7thave.atlassian.net/browse/HT-4956 */}
              <InitialSetting />
              <UserGuide />
            </AppProvider>
          </MessageProvider>
        </RecoilRoot>
        <CustomNProgress />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
